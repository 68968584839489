.app {
  text-align: center;
  min-height: 100vh;
  background-color: #FFFF00;
}

.app-header {
  display: flex;
  flex-direction: column;
  cursor: default;
  user-select: none;
  align-items: center;
  justify-content: center;
  height: calc(100px + 6vmin);
  width: calc(300px + 8vmin);
  border-radius: 20px;
  font-size: calc(10px + 3vmin) !important;
  font-weight: bolder !important;
  margin-top: 12vmin;
  background-color: #00C853;
  color: white;
  border: 0;
  padding: 40px;
  box-shadow: 3px 3px 8px white, 8px 8px 8px red, 12px 12px 8px green;
}

.app-btn {
  border-radius: 10px;
  border: 2px solid #EEFF41;
  padding: 18px 20px 10px 20px;
  width: 160px;
  margin: 40px 0;
}

.app-content {
  color: #33691E;
  font-size: calc(10px + 2vmin) !important;
  font-weight: bold !important;
  margin: 100px 0 40px 0;
  padding: 18px 20px 10px 20px;
  background-color: whitesmoke;
  border-radius: 10px;
  height: 80px;
  width: calc(300px + 8vmin);
}

.app-animation {
  animation: 300s fade-in;
}

.app-animation {
  -webkit-animation: fadeIn 600ms; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeIn 600ms; /* Firefox < 16 */
  -o-animation: fadeIn 600ms; /* Opera < 12.1 */
  animation: fadeIn 600ms;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadeIn {
  from { opacity: 0; }
  to   { opacity: 1; }
}